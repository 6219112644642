<script setup lang="ts">
import { computed } from 'vue';
import { useGuidesStore } from '~/stores/guides.store';
import { useMetaStore } from '~/stores/meta.store';

const guideStore = useGuidesStore();

await guideStore.fetchAreaGuideCountries()

const areaGuideCountries = computed(() => guideStore.countries);
const meta = computed(() => guideStore.meta);
const metaStore = useMetaStore();
metaStore.setMeta(meta.value);
</script>

<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-guides">
        <main>
            <section>
                <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mt-3 mb-16">
                    <h2 class="text-indigo-900 text-3xl tracking-wide text-center my-10">{{ $t('client.area_guides') }}</h2>
                    <div class="flex flex-col gap-11">
                        <template v-for="(country, i) in areaGuideCountries" :key="i">
                            <div class="group relative mb-2 w-2/3 cursor-pointer" :class="[i % 2 ? 'ms-auto' : '']"
                                @click="$router.push(localePath({ name: 'guides.show', params: { location: country.country_slug } }))">
                                <div class="absolute inset-0 -z-10 -bottom-6  bg-zinc-300 rounded-lg mt-6 ms-6 opacity-100 group-hover:opacity-0 transition-opacity duration-300"
                                    :class="[i % 2 ? '-right-6' : '-left-6']">
                                </div>
                                <img class="w-full h-60  object-cover rounded-lg" :src="`${country.background}?w=810&h=240&fit=cover`"
                                    :alt="country.title">
                                <div class="absolute inset-0 bg-indigo-900 opacity-30 rounded-lg"></div>
                                <div class="absolute bottom-0 left-0 p-5 flex justify-between items-center w-full">
                                    <div class="flex flex-col">
                                        <span class="text-zinc-300 text-base font-bold"></span>
                                        <h2 class="text-white text-4xl font-normal tracking-widest">{{ country.title }}
                                        </h2>
                                    </div>
                                    <div
                                        class="bg-indigo-600 rounded-full w-10 h-10 leading-10 text-center me-10 opacity-0 group-hover:translate-x-4 group-hover:opacity-100 transition-all duration-500">
                                        <i class="fa-solid fa-arrow-right text-white"></i>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </section>
        </main>
    </NuxtLayout>
</template>